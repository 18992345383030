/* @import url(https://allfont.es/cache/css/arial-narrow.css); */

/* @font-face{
  font-family:arial narrow;
  font-style:normal;
  font-weight:400;
  src:local('Arial Narrow'),local('ArialNarrow-Regular'),
  url(https://allfont.net/cache/fonts/arial-narrow_d0eb64ed2b91fe9a67f7800c1b14868b.woff) format('woff'),
  url(https://allfont.net/cache/fonts/arial-narrow_d0eb64ed2b91fe9a67f7800c1b14868b.ttf) format('truetype')
} */

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Arial Narrow', 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}