.form-box {
  background-color: #f9f9f9;
  color: #432918;
}

.form-box .app-header {
  padding: 10px 70px;
}

.form-title {
  margin: 50px 0 20px 0;
  font-size: 20px;
  padding: 0 80px;
}

.form {
  margin: 30px 80px;
  font-size: 14px;
  width: 90vw;
}

.btn-back {
  display: flex;
}

.btn-back .app-link.header {
padding-right: 5px;
}

.form-fieldset {
  border: none;
  padding: 5px;
}

.fieldset-legend {
  border-bottom: solid #a96428 2px;
  width: auto;
  padding-bottom: 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.params-title {
  position: relative;
}

.params-title::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 35px;
  content: "\f0d7";
  position: absolute;
  right: 10px;
  bottom: 7px;
  color: #432918;
} 

.form-subtitle {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin: 20px 0 10px 0;
}
.fieldset-box {
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  width: auto;
}

.show_results, .show_params {
  margin: 20px 0;
}

.action {
  display: block;
}

.action-box {
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  width: auto;
}

.input-box {
  width: 297px;
  padding: 5px;
}

.form-label {
  display: block;
  height: 35px;
  padding-bottom: 4px;
}

.map-link {
  color: blue;
  text-decoration: underline;
}

.form-input {
  border: solid 1px #432918;
  width: 100%;
}

.submit-btn-box {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.submit-btn {
  margin: 10px;
  height: 30px;
  width: 210px;
  font-size: 17px;
  color: #432918;
  text-align: center;
  text-transform: uppercase;
  background-color:#f9f9f9;
  border: 0.1px solid #432918;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 2px rgba(67, 41, 24, 0.2);
}

.pdf-btn, .reset-btn {
  margin: 10px;
  height: 30px;
  width: 315px;
  font-size: 17px;
  color: #432918;
  text-align: center;
  text-transform: uppercase;
  background-color:#f9f9f9;
  border: 0.1px solid #432918;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 2px rgba(67, 41, 24, 0.2);
}


.hidden{
  display: none;
}

.results_params .form-input {
  background-color: #f3f3f3;
}

/* .results_params .form-label {
  height: 37px;
} */

.show_results .form-label {
  height: 19px;
}

/* .show_params .props-section + .strong-axis + .weak-axis + .tension {
  height: 19px;
} */

.cumple {
  color: green;
}

.no-cumple {
  color: red;
}