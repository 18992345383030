.modal-body {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 5px;
  background-color: rgba(0,0,0, 0.5);

}

.modal-box {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 8%;
  bottom: auto;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: center;
}

.modal-header {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgb(169, 100, 40);
  color:white;
}

.modal-main {
  margin: 10px 15px;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: 400px;
}

.modal-title {
  font-family: "Arial Narrow", Arial, sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 22px;
  margin: 10px 5px;
}

.modal-close-btn {
  text-decoration: none;
  border-radius: 50%;
  width: 20px;
  height: 20Px;
  color: white;
  background-color: black;
  margin: 10px 5px;
  position: absolute;
  right: -13px;
  top: -18px;
}

.email-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin: 0 6px;
}

.email-text {
  margin: 10px;
}

.input-email {
  width: 100%;
  margin: 10px;
}

.accept-box {
  margin: 10px;
}

.accept-checkbox {
  vertical-align: middle;
  position: relative;
  margin-left: 5px;
  bottom: .08em;
}

.privacy-title-es, .privacy-title-fr {
  background-color: lightgray;
  margin: 10px;
  padding: 5px 20px;
  position: relative;
}

.privacy-title-es::after, .privacy-title-fr::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 20px;
  content: "\f0d7";
  position: absolute;
  right: 10px;
  bottom: 2.5px;
} 

.privacy-text-es, .privacy-text-fr {
  text-align: left;
  margin: 10px;
  padding: 10px 15px;
  overflow-y: scroll;
  max-height: 200px;
  scroll-behavior: smooth;
}

.privacy-text-title {
  margin: 10px 0px;
}

.privacy-text {
  margin: 8px 0;
}

.privacy-list {
  padding: 10px 0;
  margin-left: 10px;
}

.unorder-list {
  padding: 7px 0;
  padding-left: 20px;
  
}

.captcha-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

 @media screen and (max-width: 684px){
  .captcha-box {
    transform:scale(0.80);
    -webkit-transform:scale(0.80);
  }
}

@media screen and (max-width: 562px){
  .captcha-box {
    transform:scale(0.70);
    -webkit-transform:scale(0.65);
  }
}

@media screen and (max-width: 500px){
  .captcha-box {
    transform:scale(0.50);
    -webkit-transform:scale(0.50);
  }
}

@media screen and (max-width: 400px){
  .captcha-box {
    transform:scale(0.40);
    -webkit-transform:scale(0.40);
  }
}

.footer{
  margin: 0;
}