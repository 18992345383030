.app {
  height: 100%;
  background-color:#f9f9f9;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-weight: 500;
}

.app-header {
  background-color: #A96428;
  height: auto;
  padding: 10px 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-height:30vh;
}

.app-link.header {
  text-decoration: none;
}

.link-text.header {
  background-color: #A96428;
  color: white;
  font-size: 23px;
  padding: 10px 25px;
  border-radius: 5px;
  text-align: center;
}

.link-text.header:hover {
  background-color: #432918;
}

.header-logo-box {
  height: 45px;
}

.header-logo {
  height: 100%;
}

.app-main {
  background-color:#f9f9f9;
  min-height: 600px;
  height: auto;
  min-height: 83vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20%;
}


.main-logo-box {
  width: 65%;
  pointer-events: none;
  margin: 30px;
}
.app-logo {
  width: 100%;
  align-items: center;
}

.description-box {
  margin: 20px 0;
  padding-top: 15px;
  text-align: justify;
}

.description-title {
  color: #432918;
  font-size: 19px;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: solid #a96428 2px;
  padding-bottom: 20px;
}

.description-text-box {
  padding: 10px 0;
  color: #333333;
}

.description-text {
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 27px;
  word-spacing: normal;
}

.app-link {
  text-decoration: none;
}

.app-link.btn {
  text-transform: uppercase;
  background-color:#f9f9f9;
  border: 0.1px solid #432918;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 2px rgba(67, 41, 24, 0.2);
}

.link-text {
  color: rgb(67, 41, 24);
  font-size: 17px;;
  font-weight: 500;
  padding: 10px 40px;
  text-align: center;
}

.app-footer {
  display: flex;
  height: auto;
  max-height: 30vh;
  background-color: black;
  padding: 10px 0;
}

.footer-title {
  color: white;
  margin-left: 15px;
}
