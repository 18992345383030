.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 5px;
  background-color: rgba(0,0,0, 0.5);

}

.popup-inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 15%;
  bottom: auto;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: auto;
}

.popup-title-box {
  display: flex;
  justify-content: center;
  width: 100%;
}

.popup-title {
  font-family: "Arial Narrow", Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 22px;
  margin: 10px 5px;
  color: #432918;
}

.popup-btn {
  text-decoration: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  background-color: black;
  margin: 10px 5px;
  position: absolute;
  right: -13px;
  top: -18px;
}

.popup-img{
  max-height: 95%;
  max-width: 100%;
  margin: 10px 20px;
}